<template>
    <b-container class="card-body p-0">
      <b-row>
        <b-col>
          <router-link :to="{ name: 'ImpactTopic', params: { id: item.id }}"><h4 class="card-title">{{item.name}}</h4></router-link>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'ImpactTopic',
  props: ['item', 'username']
}
</script>

<style>
</style>
